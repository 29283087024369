import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Container } from "react-bootstrap"
import PropTypes from "prop-types"
import TagsLink from "../components/TagsLink"
import { RightTagsLink } from "../components/TagsLink"
const _ = require("lodash")

// Components
const Tags = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const tags = data.tagsGroup.group

  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} project${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={tagHeader} />
      <Container className="tags_header">
      <h2 className="title_font">My Work</h2>
      <ul className="tags_bar tags_bar2">
        <div>
        <TagsLink to="/work/" children="all" />
        {tags.filter(tag => tag.fieldValue !== "Indie Support").map(tag => {
          return (
            <TagsLink to={`/tags/${_.kebabCase(tag.fieldValue)}/`} children={tag.fieldValue} />
          )
        })}
        </div>
        {tags.filter(tag => tag.fieldValue === "Indie Support").map(tag => {
          return (
            <RightTagsLink to={`/tags/${_.kebabCase(tag.fieldValue)}/`} children={tag.fieldValue} />
          )
        })}
      </ul></Container>
      <Container className="work_page">
        {edges.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const featuredImgFluid = node.frontmatter.img
          const year = new Date(node.frontmatter.date).getFullYear()
          return (
            <article
              key={node.fields.slug}
              className="article_list_item"
            >
              <header>
                <h3 style={{ marginBottom: 0 }}>
                  <Link
                    style={{
                      boxShadow: `none`,
                      color: "#fff",
                      fontSize: `21px`,
                      fontWeight: `400`,
                    }}
                    to={node.fields.slug}
                  >
                    {title}
                  </Link>
                </h3>
              </header>
              <section
                style={{
                  color: "#888888",
                  fontSize: `17px`,
                  fontWeight: `300`,
                }}
              >
                <p className="article_year">{year}</p>
                <p
                  className="article_des"
                  style={{ marginBottom: `5px` }}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
                <Link
                  style={{
                    boxShadow: `none`,
                    color: "#fff",
                    fontSize: `21px`,
                    fontWeight: `400`,
                    fontFamily: `Montserrat`,
                  }}
                  to={node.fields.slug}
                >
                  {node.frontmatter.img && (
                    <Img fluid={featuredImgFluid.childImageSharp.fluid} />
                  )}
                </Link>
              </section>
            </article>
          )
        })}
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
      {/* <Link to="/work">All tags</Link> */}
      </Container>
      <Container><p>{tagHeader}</p></Container>
      </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}
export default Tags
export const pageQuery = graphql`
         query($tag: String) {
           site {
             siteMetadata {
               title
             }
           }
           tagsGroup: allMarkdownRemark(
             limit: 2000
             filter: { frontmatter: { template: { eq: "work" } } }
           ) {
             group(field: frontmatter___tags) {
               fieldValue
             }
           }
           allMarkdownRemark(
             limit: 2000
             sort: { fields: [frontmatter___date], order: DESC }
             filter: { frontmatter: { tags: { in: [$tag] } } }
           ) {
             totalCount
             edges {
               node {
                 fields {
                   slug
                 }
                 frontmatter {
                   date(formatString: "MMMM DD, YYYY")
                   template
                   title
                   tags
                   description
                   slug
                   category
                   img {
                     childImageSharp {
                       fluid(maxWidth: 800) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `